import React from "react";
import Background from "../../static/images/gradient.jpg";
import Logo from "../../static/images/RJlogo.png";
import headone from "../../static/images/head1.png";
import headtwo from "../../static/images/head2.png";
import headthree from "../../static/images/head3.png";
import headfour from "../../static/images/head4.png";
import headfive from "../../static/images/head5.png";
import headsix from "../../static/images/head6.png";
import Infotwo from "../../static/images/actuarialconsulting.png";
import Hero from "../../static/images/banner100.png";

import Infoeight from "../../static/images/appoloremove.png";
import Company2 from "../../static/images/company2.png";
import Company3 from "../../static/images/lic-removebg-preview.png";

export default function header() {
  return (
    <div className="bg-white mt-28">
      <div class="relative ">
        <div class="absolute bottom-0 left-0 w-full h-[850px] flex items-end bg-white">
          <img class="hidden lg:block  w-full h-full " src={Hero} alt="" />
        </div>
        <div
          class="relative container px-4 mx-auto -mt-10 pl-10"
          style={{ fontFamily: "Poppins" }}
        >
          <div class="max-w-xl mt-0 xl:max-w-4xl mb-6 lg:mb-12 ">
            <span class="block mb-4 mt-10  font-semibold text-blue-900">
              Charting Success since 2020
            </span>
            <h1 class="font-heading text-3xl xs:text-4xl md:text-6xl xl:text-6xl font-bold text-gray-900 mb-8 sm:mb-14">
              <span>Leaders in Actuarial Excellence </span>
              <p className=" text-base lg:text-xl lg:w-2/3 pt-6 text-blue-900">
                Expertise-driven, innovative solutions in actuarial science and
                risk management, shaping industry standards
              </p>
              {/* <span class="font-serif italic">Ever</span> */}
            </h1>
            <div class="lg:flex items-center">
              <div class="mb-8 lg:mb-0 lg:mr-8">
                <a
                  class="relative group inline-block w-full sm:w-auto py-4 px-6 text-white font-semibold bg-[#2A3C90] rounded-full overflow-hidden"
                  href="/contact"
                >
                  <div class="absolute top-0 right-full w-full h-full bg-[#2BACE2] transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                  <div class="relative flex items-center justify-center">
                    <span class="mr-4">Meet The Expert</span>
                    <span>
                      <svg
                        width="8"
                        height="12"
                        viewbox="0 0 8 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.83 5.29L2.59 1.05C2.49704 0.956274 2.38644 0.881879 2.26458 0.83111C2.14272 0.780342 2.01202 0.754204 1.88 0.754204C1.74799 0.754204 1.61729 0.780342 1.49543 0.83111C1.37357 0.881879 1.26297 0.956274 1.17 1.05C0.983753 1.23736 0.879211 1.49082 0.879211 1.755C0.879211 2.01919 0.983753 2.27264 1.17 2.46L4.71 6L1.17 9.54C0.983753 9.72736 0.879211 9.98082 0.879211 10.245C0.879211 10.5092 0.983753 10.7626 1.17 10.95C1.26344 11.0427 1.37426 11.116 1.4961 11.1658C1.61794 11.2155 1.7484 11.2408 1.88 11.24C2.01161 11.2408 2.14207 11.2155 2.26391 11.1658C2.38575 11.116 2.49656 11.0427 2.59 10.95L6.83 6.71C6.92373 6.61704 6.99813 6.50644 7.04889 6.38458C7.09966 6.26272 7.1258 6.13201 7.1258 6C7.1258 5.86799 7.09966 5.73728 7.04889 5.61543C6.99813 5.49357 6.92373 5.38297 6.83 5.29Z"
                          fill="#FFF2EE"
                        ></path>
                      </svg>
                    </span>
                  </div>
                </a>
              </div>
              <div class="flex items-center">
                {/* <div class="p-px bg-white rounded-full -ml-2">
              <div class="flex items-center justify-center w-10 h-10 rounded-full bg-gray-200">
                <span class="font-semibold">5K+</span>
              </div>
            </div> */}
              </div>
            </div>
          </div>
          <div class="xl:flex">
            <div class="max-w-2xl xl:mr-32 mb-12">
              {/* <span class="block mb-6 text-sm font-semibold text-gray-500">
                TRUSTED BY
              </span> */}
              {/* <div class="flex items-center -mx-4">
                <div class="lg:w-1/5 px-4">
                  <img class="block" src={Infoeight} alt="" />
                </div>
                <div class="lg:w-1/5 px-4">
                  <img class="block" src={Company2} alt="" />
                </div>
                <div class="lg:w-1/5 px-4">
                  <img class="block" src={Company3} alt="" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* 
      <section
        class="overflow-hidden bg-cover bg-no-repeat bg-black"
        style={{
          background: `url('${Background}')`,
          backgroundSize: "cover",
          fontFamily: "poppins",
          
        }}
      >
        <div class="container px-10 lg:mx-auto lg:px-14">
          <div class="flex flex-wrap justify-between -m-6 pt-12 lg:pt-32">
            <div class="w-full mt-4 lg:w-5/12 xl:w-1/2 p-6 lg:mt-24">
              <p class=" mb-4 text-sm md:text-md font-heading text-gray-400 font-medium ">
                A New Age Consulting firm
              </p>
              <p class="mb-14 font-heading text-3xl md:text-4xl md:leading-tight text-white font-bold">
                {" "}
                The right balance of experience and expertise!
              </p>
              <div class="flex flex-wrap -m-3 mb-20">
                <div class="w-full lg:w-auto p-3">
                  <a
                    class="font-heading lg:w-full py-2 px-6 lg:py-4 text-base text-white bg-sky-600 hover:bg-blue-900 rounded-md"
                    href="/contact"
                  >
                    Enquire Now
                  </a>
                </div>
                <div class="w-full lg:w-auto p-3">
                  <a
                    class=" font-heading lg:w-full py-2 px-6 lg:py-4 text-base text-white bg-transparent border border-gray-500 hover:border-gray-600 rounded-md"
                    href="/about"
                  >
                    Know More
                  </a>
                </div>
              </div>
              <div class="lg:max-w-md">
               
              </div>
            </div>

            <div class=" hidden md:block w-full lg:w-7/12 xl:w-1/2">
              <div class="max-w-max mx-auto">
                <div class="justify-center -m-3 mb-3 flex">
                  <div class="w-full p-3">
                    <div class="mx-auto h-48 w-40 p-3 bg-gradient-to-r from-cyan-600  to-blue-700  border-stone-50 opacity-100 transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl items-center">
                      <a
                        class="flex flex-wrap"
                        href="/ActuarialConsulting"
                      >
                        <div class=" lg:w-auto lg:p-2 text-center">
                          <img
                            src={headone}
                            alt=""
                            style={{ height: "70px", borderRadius: "50%"}}
                          />
                        </div>
                        <div class="w-auto p-2">
                          <p class="mb-1 mt-6 text-sm text-white">
                            Actuarial Consulting <br></br>and Valuation
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="w-full p-3">
                    <div class="mx-auto h-48 w-40 p-3 bg-gradient-to-r from-cyan-600 to-blue-700 transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
                      <a class="flex flex-wrap -m-2" href="/EmployeeBenefits">
                        <div class=" lg:w-auto lg:p-2">
                          <img
                            src={headtwo}
                            alt=""
                            style={{ width: "70px", borderRadius: "50%" }}
                          />
                        </div>
                        <div class="w-auto p-2">
                          <p class=" mt-4 text-sm text-white">
                            Employee Benefits <br></br>Evaluation
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="flex justify-center max-w-max -m-3 mb-3">
                 

                    <div class="flex w-full sm:w-1/2 p-3">
                    <div class="mx-auto h-48 w-40 p-3 bg-gradient-to-r from-cyan-600 to-blue-700  transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
                      <a class="flex flex-wrap -m-2" href="/BusinessConsulting">
                        <div class="lg:w-auto lg:p-2">
                          <img
                            src={headthree}
                            alt=""
                            style={{ width: "70px", borderRadius: "50%" }}
                          />
                        </div>
                        <div class="w-auto p-2">
                          <p class="mb-1 mt-4 text-sm text-white">
                            Enterprise Risk<br></br> Management
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>

                
                  <div class="w-full sm:w-1/2 p-3">
                    <div class="mx-auto h-48 w-40 p-3 bg-gradient-to-r from-cyan-600 to-blue-700 transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
                      <a
                        class="flex flex-wrap -m-2"
                        href="/TechnologySolutions"
                      >
                        <div class=" lg:w-auto lg:p-2">
                          <img
                            src={headfour}
                            alt=""
                            style={{ width: "70px", borderRadius: "50%" }}
                          />
                        </div>
                        <div class="w-auto p-2">
                          <p class="mb-1 mt-4 text-sm text-white">
                            Investment<br></br> Consulting
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div class="w-full sm:w-1/2 p-3">
                    <div class="mx-auto h-48 w-40 p-3 bg-gradient-to-r from-cyan-600 to-blue-700 transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
                      <a class="flex flex-wrap -m-2" href="/DataMining">
                        <div class=" lg:w-auto lg:p-2">
                          <img
                            src={headfive}
                            alt=""
                            style={{ width: "70px", borderRadius: "50%" }}
                          />
                        </div>
                        <div class="w-auto p-2">
                          <p class="mb-1 mt-4 text-sm text-white">
                            Technology <br></br>Solutions
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>

                </div>
                <div class="flex flex-wrap justify-center max-w-max ml-44 mb-20">
                  <div class=" justify-center max-w-max">
                  <div class="w-full sm:w-1/2 p-3">
                    <div class="mx-auto h-48 w-40 p-3 bg-gradient-to-r from-cyan-600 to-blue-700 transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
                      <a class="flex flex-wrap -m-2" href="/MachineLearningAI">
                        <div class=" lg:w-auto lg:p-2">
                          <img
                            src={headsix}
                            alt=""
                            style={{ width: "70px", borderRadius: "50%" }}
                          />
                        </div>
                        <div class="w-auto p-2">
                          <p class="mb-1 mt-4 text-sm text-white">
                            Business<br></br>Management<br></br> Consulting
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                </div>
              
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
}
